//react packages
import React, { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Prism from "prismjs";
import { Row, Col, Card, Button, Badge } from "react-bootstrap";
//empty state
import OrderEmptyState from "../../_emptyState/order-empty";
//selectors
import { getOrdersByOrderType } from "../../../application/selectors/indexSelector";
import Environment from "../../../infrastructure/core/Environment";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { Link } from "react-router-dom";
import viewOrder from "../../../assets/img/icons/search-file.png";

export default function HomeDelivery({
  handleAddItem,
  orderType,
  handleOrderDetails,
  handleBillPrintRequest,
  selectedTabSection,
}) {
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const commonfields = JSON.parse(localStorage.getItem("CommonFields"));
  //fetching and storing orders by order type
  const ordersByOrderType = useSelector(getOrdersByOrderType);
  //storing the orders by ordertype in useMemo (cache)
  const memoizedOrdersByOrderType = useMemo(() => {
    return ordersByOrderType;
  }, [ordersByOrderType]);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  return (
    <React.Fragment>
      <div className="home-delivery-wrapper">

        <div className="d-flex justify-content-between align-items-center">
          <h3 className="fs-16 fw-semibold text-black-50">List of Pending {orderType?.typeName}</h3>
          <Button variant="outline-dark"
            className="gap-1 d-flex align-items-center btn btn-dark text-white fw-semibold"
            onClick={() => handleAddItem({ tableName: orderType?.typeName, tableTypeId: orderType?.id }, true)}>
            <i className="ri-add-line fs-16"></i>
            <span className="d-sm-inline fs-14">Create New Order</span>
          </Button>
        </div>
        {memoizedOrdersByOrderType && memoizedOrdersByOrderType?.length !== 0 ? (
          <Row className="g-3">
            {memoizedOrdersByOrderType?.map((item, index) => (
              <Col xl={3} lg={4} md={6} sm={12} key={index}>
                <Card className="card-one">
                  <Card.Body className="pb-5">
                    <div className="mb-2 fs-14 d-flex justify-content-between align-items-center">
                      <h3 className="text-dark fs-14 mb-0 fw-bold pt-2">
                        {/* {item.tokenNumber} :  */}
                        {`${item.orderNumber.substring(0, item.orderNumber.indexOf("-") + 1)}
                        ${item.orderNumber.substring(2, 8)}/${item.orderNumber.substring(item.orderNumber.lastIndexOf("/") + 1)}`}
                      </h3>
                      <Badge
                        bg={`${item.orderStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e606" ? "primary" :
                          item.orderStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e610" ? "success" :
                            item.orderStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e601" ? "success" : "primary"
                          }`}
                        className="fs-14 badge-pill"
                      >
                        {commonfields?.find((cmdflds) => cmdflds.id === item.orderStatus)?.fieldValue}
                      </Badge>
                    </div>


                    <div className="d-flex align-items-center gap-3 flex-wrap mb-2">
                      <h4 className="mb-0 fs-12 text-dark">
                        <i className="ri-user-line fs-14 me-1 align-middle"></i>
                        <span className="fw-bold text-dark align-middle">{item?.customerId === Environment.defaultValue ? "Guest" : item?.customerDetails?.fullName}</span>
                      </h4>
                      {item?.customerId !== Environment.defaultValue && (
                        <h4 className="mb-0 fs-12 text-dark">
                          <i className="ri-phone-line fs-14 me-1 align-middle"></i>
                          <span className="fw-bold text-dark align-middle">{item?.customerDetails?.contactNumber}</span>
                        </h4>
                      )}
                    </div>

                    {item?.customerId !== Environment.defaultValue &&
                      orderType?.id ===
                      "a5ca48b8-bfd1-42d6-8db1-7021c131e003" &&
                      item?.addressId !== Environment.defaultValue && (
                        <h4 className="mb-0 fs-12 text-dark mb-2 two-line-ellipsis">
                          <i className="ri-map-pin-line fs-14 me-1 align-middle"></i>
                          <span className="text-dark align-middle">
                            {item?.deliveryAddress?.roomNo},{" "}
                            {item?.deliveryAddress?.buildingName},{" "}
                            {item?.deliveryAddress?.location},{" "}
                            {item?.deliveryAddress?.landmark},{" "}
                            {item?.deliveryAddress?.zipCode},{" "}
                            {item?.deliveryAddress?.city},{" "}
                            {item?.deliveryAddress?.state},{" "}
                            {item?.deliveryAddress?.country},{" "}
                            {item?.deliveryAddress?.zipCode}
                          </span>
                        </h4>
                      )}

                    <div className="order-price-bttn d-flex align-items-center justify-content-between">
                      <h4 className="mb-0 fs-16 fw-semibold text-dark">
                        {parsedBranchData?.currencySymbol}{" "}
                        <span className="me-2 text-dark"
                        // className={`me-2 ${
                        //   item.paymentStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e701" ? "text-success" : 
                        //   item.paymentStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e702" ? "text-danger" : "text-primary"
                        // }`}
                        >
                          {item?.grandTotalPrice}
                        </span>

                        <Badge
                          bg={`${item.paymentStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e701" ? "success" :
                            item.paymentStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e702" ? "danger" : "primary"
                            }`}
                          className="badge-pill fs-12">
                          {commonfields?.find((cmdflds) => cmdflds.id === item.paymentStatus)?.fieldValue}
                        </Badge>
                      </h4>

                      <div className="d-flex align-items-center">
                        <Button
                          variant="white"
                          className="text-dark me-2 fw-medium border-0 p-0"
                          onClick={() =>
                            handleBillPrintRequest(
                              null,
                              selectedTabSection,
                              item
                            )
                          }
                        >
                          <i className="ri-printer-line fs-22"></i>
                        </Button>

                        <Link
                          className="text-dark fw-medium"
                          onClick={() =>
                            handleOrderDetails(item.id, {}, { tableName: orderType?.typeName, tableTypeId: orderType?.id, }, true)
                          }
                        >
                          <img src={viewOrder} className="wt-22" alt="view" />
                        </Link>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <OrderEmptyState />
        )}
      </div>
    </React.Fragment>
  );
}
