import axios from "axios";
import Environment from "../Environment";

const jwtInterceptor = axios.create();
let isRefreshing = false;
let refreshSubscribers = [];

// Helper function to add requests to the queue
const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

// Helper function to execute all queued requests after refresh
const onRrefreshed = (token) => {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
};

jwtInterceptor.interceptors.request.use((config) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  if (storedToken) {
    config.headers.Authorization = `Bearer ${storedToken}`;
    console.log(storedToken);
    
  }
  return config;
});

jwtInterceptor.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response: errorResponse } = error;

    if (errorResponse && errorResponse.status === 401) {
      // If refresh is already in progress, queue the request
      if (isRefreshing) {
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            error.config.headers.Authorization = `Bearer ${token}`;
            resolve(axios(error.config));
          });
        });
      }

      isRefreshing = true;

      try {
        const storedToken = JSON.parse(localStorage.getItem("Token"));
        const userProfile = JSON.parse(localStorage.getItem("userProfile"));

        if (!storedToken || !userProfile) {
          window.location.href = "/account/login";
          throw new Error("No token available for refresh");
        }

        const id = window.location.href;
        const domain = new URL(id).hostname === "localhost" ? "ad.fudeasy.com" : new URL(id).hostname;

        const refreshResponse = await axios.post(`${Environment.Account_Url}refresh-token`, {
          accessToken: storedToken,
          refreshToken: userProfile.refreshToken,
          domain,
        });

        const { accessToken, refreshToken } = refreshResponse.data;

        // Update token in local storage and user profile
        const updatedUserProfile = {
          ...userProfile,
          refreshToken,
        };
        localStorage.setItem("Token", JSON.stringify(accessToken));
        localStorage.setItem("userProfile", JSON.stringify(updatedUserProfile));

        // Notify all requests waiting for the new token
        onRrefreshed(accessToken);
        isRefreshing = false;

        // Retry the failed request with the new token
        error.config.headers.Authorization = `Bearer ${accessToken}`;
        return axios(error.config);
      } catch (refreshError) {
        // Refresh token failed, redirect to login
        console.error("Token refresh failed:", refreshError);
        window.location.href = "/account/login";
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default jwtInterceptor;
